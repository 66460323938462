<template>
  <div class="container" v-if="processing">
    <div class="loader">
      <svg viewBox="-5 -5 159 64" fill="white" xmlns="http://www.w3.org/2000/svg">
        <path class="filler" fill-rule="evenodd"
              d="M -5 -5 l 0 64 l 159 0 l 0 -64 z
                  M139.532 1.74714L139.552 1.88766L139.542 1.8977C139.562 2.02817 139.582 2.17866 139.592 2.31918C139.722 3.7442 139.632 5.19936 139.542 6.61441C139.542 6.62439 139.472 7.67817 139.452 8.18999C139.452 8.35053 139.382 8.50109 139.263 8.61146C139.153 8.71179 139.003 8.77201 138.854 8.77201H138.834C138.674 8.77201 138.524 8.70175 138.415 8.58133C138.305 8.46091 138.255 8.30037 138.255 8.13976C138.275 7.55775 138.305 6.99576 138.345 6.44376L138.353 6.32524C138.44 4.97537 138.521 3.71664 138.395 2.42955C138.385 2.36432 138.377 2.30411 138.37 2.2439C138.362 2.18369 138.355 2.12348 138.345 2.05824C138.325 1.94787 138.315 1.86757 138.305 1.77728C138.285 1.67689 138.285 1.54642 138.285 1.39593C138.285 1.1952 138.275 0.954361 138.245 0.743593C138.195 0.40242 138.375 0.101305 138.674 0.0210653C138.983 -0.0692813 139.343 0.141487 139.412 0.452585C139.442 0.583052 139.462 0.713518 139.462 0.87406L139.482 1.16513C139.482 1.2281 139.488 1.30962 139.501 1.4728L139.512 1.62672C139.522 1.6669 139.532 1.74714 139.532 1.74714Z
                  M136.828 9.42428C136.33 9.40425 135.821 9.37411 135.272 9.33399L135.145 9.32591C133.779 9.23783 132.376 9.14754 130.992 9.28383C130.882 9.29118 130.756 9.30937 130.638 9.32646C130.595 9.33264 130.553 9.33871 130.513 9.34404L130.314 9.37411C130.064 9.40425 129.945 9.40425 129.625 9.40425C129.456 9.40425 129.306 9.42428 129.146 9.46446C128.827 9.55474 128.638 9.88593 128.717 10.2071C128.757 10.3676 128.857 10.4981 128.997 10.5784C129.097 10.6386 129.196 10.6486 129.296 10.6486H129.426C129.473 10.6441 129.52 10.6401 129.565 10.6366C129.611 10.633 129.657 10.6298 129.701 10.6273C129.805 10.6214 129.902 10.6185 129.994 10.6185C130.144 10.6185 130.274 10.6061 130.381 10.5959L130.434 10.591L130.463 10.5884L130.733 10.5483C130.797 10.5383 130.859 10.5307 130.922 10.5233C130.984 10.5157 131.047 10.5082 131.112 10.4981C132.356 10.3757 133.557 10.4476 134.821 10.5233L135.073 10.5383C135.631 10.5784 136.2 10.6085 136.789 10.6386C136.938 10.6486 137.108 10.5884 137.218 10.4781C137.337 10.3676 137.407 10.2171 137.407 10.0565C137.407 9.89598 137.357 9.73538 137.247 9.625C137.138 9.50458 136.988 9.43432 136.828 9.43432V9.42428Z
                  M148.421 9.45441C148.581 9.45441 148.731 9.52467 148.84 9.64509V9.66518C148.95 9.77556 149 9.9361 149 10.0967C148.99 10.2572 148.92 10.4078 148.8 10.5182C148.681 10.6286 148.531 10.6888 148.371 10.6788C148.042 10.6587 147.683 10.6888 147.334 10.7289H147.164C147.107 10.7334 147.046 10.7397 146.982 10.7463C146.937 10.7511 146.89 10.756 146.842 10.7604C146.807 10.7637 146.771 10.7667 146.735 10.7691L146.629 10.7775L146.548 10.7839L146.547 10.7841C146.212 10.811 145.86 10.8393 145.508 10.8393H145.259C143.942 10.7892 142.545 10.759 140.989 10.759H140.939C140.779 10.759 140.62 10.6888 140.51 10.5784C140.39 10.458 140.33 10.3074 140.33 10.1469C140.33 9.98633 140.4 9.82572 140.51 9.71535C140.65 9.56479 140.859 9.53471 141.059 9.53471C142.585 9.53471 143.972 9.56479 145.309 9.61502C145.728 9.625 146.107 9.59492 146.516 9.5548C146.58 9.5477 146.643 9.54182 146.708 9.53588C146.825 9.52504 146.946 9.51395 147.074 9.49453H147.234C147.603 9.46446 148.022 9.43432 148.421 9.45441Z
                  M139.392 18.4964C139.387 18.4075 139.378 18.3186 139.37 18.2331L139.365 18.1823L139.36 18.1264C139.357 18.0951 139.355 18.0645 139.353 18.0348V17.9344C139.303 17.5229 139.273 17.1316 139.283 16.7201C139.333 15.3653 139.363 13.9303 139.363 12.3145C139.363 12.154 139.303 12.0034 139.183 11.883C138.953 11.6522 138.554 11.6522 138.325 11.883C138.215 11.9934 138.145 12.154 138.145 12.3145C138.145 13.9102 138.115 15.3352 138.066 16.67C138.047 17.1293 138.088 17.5887 138.125 18.0174L138.135 18.1352L138.138 18.1751C138.141 18.2106 138.144 18.2457 138.147 18.2802C138.15 18.3178 138.154 18.3547 138.158 18.3911C138.164 18.4583 138.171 18.5236 138.175 18.5867V18.7574C138.215 19.1287 138.245 19.4698 138.225 19.8111C138.225 19.9716 138.275 20.1322 138.385 20.2526C138.495 20.3731 138.644 20.4433 138.804 20.4433H138.834C138.983 20.4433 139.123 20.3932 139.233 20.2827C139.353 20.1723 139.422 20.0118 139.432 19.8512C139.448 19.5323 139.427 19.2133 139.408 18.9131C139.402 18.826 139.397 18.7404 139.392 18.657V18.4964Z
                  M41.7343 22.1021C41.8521 22.1021 41.9521 22.108 42.0287 22.1138V22.1196C42.0488 22.1211 42.0752 22.1226 42.1077 22.1245L42.1188 22.1251C42.2099 22.1303 42.3459 22.138 42.529 22.1545H42.6703V28.427L42.5054 28.4096C42.1405 28.3688 41.805 28.3397 41.5224 28.3281C41.2399 28.3106 41.0044 28.3048 40.8279 28.3048C38.5263 28.3048 36.9605 29.0496 36.1776 30.5159C35.7361 31.3479 35.5125 32.6571 35.5125 34.4144V45.2196H29.2788V22.6316H35.2005V26.1519C36.001 24.8893 36.7192 24.0048 37.3372 23.5044C38.4615 22.5734 39.939 22.1021 41.7343 22.1021Z
                  M19.2013 21.7937C18.077 20.5253 16.3759 19.8794 14.1567 19.8794H14.139C11.8846 19.8794 10.0775 20.7929 8.77657 22.5909C7.46391 24.4063 6.79875 27.0247 6.79875 30.353C6.79875 33.6813 7.5051 36.2124 8.8884 37.859C10.2717 39.4999 11.9905 40.3319 14.1685 40.3319C16.3464 40.3319 18.0299 39.6221 19.166 38.2198C19.8017 37.4634 20.3374 36.2996 20.7553 34.7752L20.7848 34.6704H27.3834L27.3539 34.8392C26.8006 38.1383 25.3644 40.8673 23.0863 42.9387C20.7965 45.0102 17.8239 46.0633 14.2568 46.0633C9.84789 46.0633 6.32783 44.6378 3.8026 41.839C1.27735 39.0286 0 35.1301 0 30.2482C0 24.9649 1.45394 20.8395 4.32647 17.9709C6.82819 15.4747 10.0539 14.2062 13.9095 14.2062C19.0659 14.2062 22.898 15.9053 25.2937 19.2626C26.6123 21.1479 27.3304 23.0622 27.4364 24.9649V25.1162H20.7965L20.7671 25.0115C20.355 23.5917 19.8312 22.5094 19.2013 21.7937Z
                  M44.7658 22.6316H51.0407V45.2196H44.7658V22.6316Z
                  M44.7658 14.5961H51.0407V20.2692H44.7658V14.5961Z
                  M60.2999 29.9166C60.865 30.2191 62.9194 30.7603 66.41 31.5167H66.4159C68.7763 32.0695 70.5716 32.9132 71.7607 34.0303C72.9439 35.1766 73.5443 36.6196 73.5443 38.3303C73.5443 40.5763 72.6907 42.4325 71.0013 43.8523C69.3178 45.2662 66.7632 45.9819 63.196 45.9819C59.6289 45.9819 56.9565 45.2196 55.2436 43.7243C53.5306 42.223 52.6594 40.2796 52.6594 37.9579V37.8125H58.9166L58.9343 37.9405C59.0579 38.9413 59.3169 39.657 59.7054 40.0702C60.4059 40.8091 61.7362 41.1873 63.6552 41.1873C64.7853 41.1873 65.6918 41.0244 66.3511 40.6927C66.9868 40.3785 67.2929 39.8956 67.2929 39.2904C67.2929 38.6853 67.0457 38.2372 66.5336 37.923C65.9979 37.5914 63.9436 37.0095 60.4294 36.1833C57.863 35.5548 56.0205 34.7519 54.961 33.7976C53.8897 32.8433 53.3422 31.4585 53.3422 29.678C53.3422 27.5833 54.184 25.7621 55.844 24.2608C57.498 22.7654 59.8526 22.0032 62.8429 22.0032C65.6742 22.0032 68.017 22.5676 69.8123 23.6848C71.6077 24.8253 72.6554 26.7978 72.9203 29.5616L72.938 29.7188H66.7455L66.7337 29.5907C66.6513 28.875 66.4453 28.299 66.1215 27.8859C65.5035 27.1411 64.4322 26.757 62.9252 26.757C61.6773 26.757 60.7885 26.9491 60.2705 27.3215C59.7584 27.6938 59.5112 28.107 59.5112 28.5958C59.5112 29.2067 59.7702 29.6431 60.2999 29.9166Z
                  M87.6186 22.1429C90.2851 22.1429 92.5749 23.132 94.4291 25.0754H94.4173C96.2598 27.0189 97.1898 29.7013 97.1898 33.6464C97.1898 37.5913 96.2774 40.6462 94.4762 42.7235C92.6691 44.8065 90.3086 45.8655 87.4655 45.8655C85.6525 45.8655 84.1162 45.4058 82.9095 44.5039C82.3444 44.0733 81.7852 43.474 81.2436 42.7118V54H75.1159V22.6374H81.0611V25.5991C81.6263 24.802 82.2266 24.1561 82.8506 23.6674C84.1809 22.6549 85.7879 22.1429 87.6186 22.1429ZM89.5611 38.8541C90.4028 37.6321 90.8266 35.9738 90.8266 33.9256L90.8384 33.9198C90.8384 32.2382 90.444 30.7254 89.667 29.4336C88.9077 28.1652 87.6539 27.5193 85.9351 27.5193C83.8395 27.5193 82.4444 28.4736 81.6615 30.4344C81.2554 31.476 81.0494 32.8201 81.0494 34.4319C81.0494 36.963 81.7322 38.761 83.0802 39.7792C83.8748 40.3669 84.8343 40.6694 85.9292 40.6694C87.5067 40.6694 88.7311 40.0585 89.5611 38.8541Z
                  M99.0087 14.5961H105.284V20.2692H99.0087V14.5961Z
                  M99.0087 22.6316H105.284V45.2196H99.0087V22.6316Z
                  M120.422 22.1021C122.777 22.1021 124.737 22.7247 126.238 23.9466H126.232C127.745 25.1744 128.51 27.2342 128.51 30.0621V45.2196H122.17V31.5109C122.17 30.3646 122.017 29.4802 121.711 28.875C121.152 27.7753 120.116 27.24 118.533 27.24C116.578 27.24 115.272 28.0313 114.548 29.6664C114.171 30.5333 113.982 31.6563 113.982 33.0063V45.2196H107.814V22.6723H113.8V25.5933C114.471 24.6449 115.113 23.935 115.719 23.4812C116.955 22.5676 118.539 22.1021 120.422 22.1021Z"/>
        <path
            d="M139.532 1.74714L139.552 1.88766L139.542 1.8977C139.562 2.02817 139.582 2.17866 139.592 2.31918C139.722 3.7442 139.632 5.19936 139.542 6.61441C139.542 6.62439 139.472 7.67817 139.452 8.18999C139.452 8.35053 139.382 8.50109 139.263 8.61146C139.153 8.71179 139.003 8.77201 138.854 8.77201H138.834C138.674 8.77201 138.524 8.70175 138.415 8.58133C138.305 8.46091 138.255 8.30037 138.255 8.13976C138.275 7.55775 138.305 6.99576 138.345 6.44376L138.353 6.32524C138.44 4.97537 138.521 3.71664 138.395 2.42955C138.385 2.36432 138.377 2.30411 138.37 2.2439C138.362 2.18369 138.355 2.12348 138.345 2.05824C138.325 1.94787 138.315 1.86757 138.305 1.77728C138.285 1.67689 138.285 1.54642 138.285 1.39593C138.285 1.1952 138.275 0.954361 138.245 0.743593C138.195 0.40242 138.375 0.101305 138.674 0.0210653C138.983 -0.0692813 139.343 0.141487 139.412 0.452585C139.442 0.583052 139.462 0.713518 139.462 0.87406L139.482 1.16513C139.482 1.2281 139.488 1.30962 139.501 1.4728L139.512 1.62672C139.522 1.6669 139.532 1.74714 139.532 1.74714Z
                  M136.828 9.42428C136.33 9.40425 135.821 9.37411 135.272 9.33399L135.145 9.32591C133.779 9.23783 132.376 9.14754 130.992 9.28383C130.882 9.29118 130.756 9.30937 130.638 9.32646C130.595 9.33264 130.553 9.33871 130.513 9.34404L130.314 9.37411C130.064 9.40425 129.945 9.40425 129.625 9.40425C129.456 9.40425 129.306 9.42428 129.146 9.46446C128.827 9.55474 128.638 9.88593 128.717 10.2071C128.757 10.3676 128.857 10.4981 128.997 10.5784C129.097 10.6386 129.196 10.6486 129.296 10.6486H129.426C129.473 10.6441 129.52 10.6401 129.565 10.6366C129.611 10.633 129.657 10.6298 129.701 10.6273C129.805 10.6214 129.902 10.6185 129.994 10.6185C130.144 10.6185 130.274 10.6061 130.381 10.5959L130.434 10.591L130.463 10.5884L130.733 10.5483C130.797 10.5383 130.859 10.5307 130.922 10.5233C130.984 10.5157 131.047 10.5082 131.112 10.4981C132.356 10.3757 133.557 10.4476 134.821 10.5233L135.073 10.5383C135.631 10.5784 136.2 10.6085 136.789 10.6386C136.938 10.6486 137.108 10.5884 137.218 10.4781C137.337 10.3676 137.407 10.2171 137.407 10.0565C137.407 9.89598 137.357 9.73538 137.247 9.625C137.138 9.50458 136.988 9.43432 136.828 9.43432V9.42428Z
                  M148.421 9.45441C148.581 9.45441 148.731 9.52467 148.84 9.64509V9.66518C148.95 9.77556 149 9.9361 149 10.0967C148.99 10.2572 148.92 10.4078 148.8 10.5182C148.681 10.6286 148.531 10.6888 148.371 10.6788C148.042 10.6587 147.683 10.6888 147.334 10.7289H147.164C147.107 10.7334 147.046 10.7397 146.982 10.7463C146.937 10.7511 146.89 10.756 146.842 10.7604C146.807 10.7637 146.771 10.7667 146.735 10.7691L146.629 10.7775L146.548 10.7839L146.547 10.7841C146.212 10.811 145.86 10.8393 145.508 10.8393H145.259C143.942 10.7892 142.545 10.759 140.989 10.759H140.939C140.779 10.759 140.62 10.6888 140.51 10.5784C140.39 10.458 140.33 10.3074 140.33 10.1469C140.33 9.98633 140.4 9.82572 140.51 9.71535C140.65 9.56479 140.859 9.53471 141.059 9.53471C142.585 9.53471 143.972 9.56479 145.309 9.61502C145.728 9.625 146.107 9.59492 146.516 9.5548C146.58 9.5477 146.643 9.54182 146.708 9.53588C146.825 9.52504 146.946 9.51395 147.074 9.49453H147.234C147.603 9.46446 148.022 9.43432 148.421 9.45441Z
                  M139.392 18.4964C139.387 18.4075 139.378 18.3186 139.37 18.2331L139.365 18.1823L139.36 18.1264C139.357 18.0951 139.355 18.0645 139.353 18.0348V17.9344C139.303 17.5229 139.273 17.1316 139.283 16.7201C139.333 15.3653 139.363 13.9303 139.363 12.3145C139.363 12.154 139.303 12.0034 139.183 11.883C138.953 11.6522 138.554 11.6522 138.325 11.883C138.215 11.9934 138.145 12.154 138.145 12.3145C138.145 13.9102 138.115 15.3352 138.066 16.67C138.047 17.1293 138.088 17.5887 138.125 18.0174L138.135 18.1352L138.138 18.1751C138.141 18.2106 138.144 18.2457 138.147 18.2802C138.15 18.3178 138.154 18.3547 138.158 18.3911C138.164 18.4583 138.171 18.5236 138.175 18.5867V18.7574C138.215 19.1287 138.245 19.4698 138.225 19.8111C138.225 19.9716 138.275 20.1322 138.385 20.2526C138.495 20.3731 138.644 20.4433 138.804 20.4433H138.834C138.983 20.4433 139.123 20.3932 139.233 20.2827C139.353 20.1723 139.422 20.0118 139.432 19.8512C139.448 19.5323 139.427 19.2133 139.408 18.9131C139.402 18.826 139.397 18.7404 139.392 18.657V18.4964Z
                  M41.7343 22.1021C41.8521 22.1021 41.9521 22.108 42.0287 22.1138V22.1196C42.0488 22.1211 42.0752 22.1226 42.1077 22.1245L42.1188 22.1251C42.2099 22.1303 42.3459 22.138 42.529 22.1545H42.6703V28.427L42.5054 28.4096C42.1405 28.3688 41.805 28.3397 41.5224 28.3281C41.2399 28.3106 41.0044 28.3048 40.8279 28.3048C38.5263 28.3048 36.9605 29.0496 36.1776 30.5159C35.7361 31.3479 35.5125 32.6571 35.5125 34.4144V45.2196H29.2788V22.6316H35.2005V26.1519C36.001 24.8893 36.7192 24.0048 37.3372 23.5044C38.4615 22.5734 39.939 22.1021 41.7343 22.1021Z
                  M19.2013 21.7937C18.077 20.5253 16.3759 19.8794 14.1567 19.8794H14.139C11.8846 19.8794 10.0775 20.7929 8.77657 22.5909C7.46391 24.4063 6.79875 27.0247 6.79875 30.353C6.79875 33.6813 7.5051 36.2124 8.8884 37.859C10.2717 39.4999 11.9905 40.3319 14.1685 40.3319C16.3464 40.3319 18.0299 39.6221 19.166 38.2198C19.8017 37.4634 20.3374 36.2996 20.7553 34.7752L20.7848 34.6704H27.3834L27.3539 34.8392C26.8006 38.1383 25.3644 40.8673 23.0863 42.9387C20.7965 45.0102 17.8239 46.0633 14.2568 46.0633C9.84789 46.0633 6.32783 44.6378 3.8026 41.839C1.27735 39.0286 0 35.1301 0 30.2482C0 24.9649 1.45394 20.8395 4.32647 17.9709C6.82819 15.4747 10.0539 14.2062 13.9095 14.2062C19.0659 14.2062 22.898 15.9053 25.2937 19.2626C26.6123 21.1479 27.3304 23.0622 27.4364 24.9649V25.1162H20.7965L20.7671 25.0115C20.355 23.5917 19.8312 22.5094 19.2013 21.7937Z
                  M44.7658 22.6316H51.0407V45.2196H44.7658V22.6316Z
                  M44.7658 14.5961H51.0407V20.2692H44.7658V14.5961Z
                  M60.2999 29.9166C60.865 30.2191 62.9194 30.7603 66.41 31.5167H66.4159C68.7763 32.0695 70.5716 32.9132 71.7607 34.0303C72.9439 35.1766 73.5443 36.6196 73.5443 38.3303C73.5443 40.5763 72.6907 42.4325 71.0013 43.8523C69.3178 45.2662 66.7632 45.9819 63.196 45.9819C59.6289 45.9819 56.9565 45.2196 55.2436 43.7243C53.5306 42.223 52.6594 40.2796 52.6594 37.9579V37.8125H58.9166L58.9343 37.9405C59.0579 38.9413 59.3169 39.657 59.7054 40.0702C60.4059 40.8091 61.7362 41.1873 63.6552 41.1873C64.7853 41.1873 65.6918 41.0244 66.3511 40.6927C66.9868 40.3785 67.2929 39.8956 67.2929 39.2904C67.2929 38.6853 67.0457 38.2372 66.5336 37.923C65.9979 37.5914 63.9436 37.0095 60.4294 36.1833C57.863 35.5548 56.0205 34.7519 54.961 33.7976C53.8897 32.8433 53.3422 31.4585 53.3422 29.678C53.3422 27.5833 54.184 25.7621 55.844 24.2608C57.498 22.7654 59.8526 22.0032 62.8429 22.0032C65.6742 22.0032 68.017 22.5676 69.8123 23.6848C71.6077 24.8253 72.6554 26.7978 72.9203 29.5616L72.938 29.7188H66.7455L66.7337 29.5907C66.6513 28.875 66.4453 28.299 66.1215 27.8859C65.5035 27.1411 64.4322 26.757 62.9252 26.757C61.6773 26.757 60.7885 26.9491 60.2705 27.3215C59.7584 27.6938 59.5112 28.107 59.5112 28.5958C59.5112 29.2067 59.7702 29.6431 60.2999 29.9166Z
                  M87.6186 22.1429C90.2851 22.1429 92.5749 23.132 94.4291 25.0754H94.4173C96.2598 27.0189 97.1898 29.7013 97.1898 33.6464C97.1898 37.5913 96.2774 40.6462 94.4762 42.7235C92.6691 44.8065 90.3086 45.8655 87.4655 45.8655C85.6525 45.8655 84.1162 45.4058 82.9095 44.5039C82.3444 44.0733 81.7852 43.474 81.2436 42.7118V54H75.1159V22.6374H81.0611V25.5991C81.6263 24.802 82.2266 24.1561 82.8506 23.6674C84.1809 22.6549 85.7879 22.1429 87.6186 22.1429ZM89.5611 38.8541C90.4028 37.6321 90.8266 35.9738 90.8266 33.9256L90.8384 33.9198C90.8384 32.2382 90.444 30.7254 89.667 29.4336C88.9077 28.1652 87.6539 27.5193 85.9351 27.5193C83.8395 27.5193 82.4444 28.4736 81.6615 30.4344C81.2554 31.476 81.0494 32.8201 81.0494 34.4319C81.0494 36.963 81.7322 38.761 83.0802 39.7792C83.8748 40.3669 84.8343 40.6694 85.9292 40.6694C87.5067 40.6694 88.7311 40.0585 89.5611 38.8541Z
                  M99.0087 14.5961H105.284V20.2692H99.0087V14.5961Z
                  M99.0087 22.6316H105.284V45.2196H99.0087V22.6316Z
                  M120.422 22.1021C122.777 22.1021 124.737 22.7247 126.238 23.9466H126.232C127.745 25.1744 128.51 27.2342 128.51 30.0621V45.2196H122.17V31.5109C122.17 30.3646 122.017 29.4802 121.711 28.875C121.152 27.7753 120.116 27.24 118.533 27.24C116.578 27.24 115.272 28.0313 114.548 29.6664C114.171 30.5333 113.982 31.6563 113.982 33.0063V45.2196H107.814V22.6723H113.8V25.5933C114.471 24.6449 115.113 23.935 115.719 23.4812C116.955 22.5676 118.539 22.1021 120.422 22.1021Z"/>
      </svg>
    </div>
  </div>
</template>

<script setup>
import {computed} from "vue";
import {useStore} from "vuex";

const store = useStore();
const processing = computed(() => store.state.processing);
</script>

<style scoped lang="less">

.container {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);

  .loader {
  display: flex;
    svg {
      width: 20vw;
    }
  }
}


path.filler {
  animation: filler 3s infinite ease;
}

path:not(.filler) {
  animation: letter 3s infinite ease;
}

@keyframes filler {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes letter {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>