import {createRouter, createWebHistory} from 'vue-router'
import store from "@/store";

const routes = [
    {
        name: 'home',
        path: '/',
        component: () => import('@/views/Root'),
    },
    {
        name: 'users',
        path: '/users',
        component: () => import('@/views/Users'),
        meta: {tool: 'users'}
    },
    {
        name: 'brands',
        path: '/brands',
        component: () => import('@/views/Brands'),
        meta: {tool: 'brands'}
    },
    {
        name: 'creators',
        path: '/creators',
        component: () => import('@/views/Creators'),
        meta: {tool: 'creators'}
    },
    {
        name: 'brand_campaigns',
        path: '/:brand_id',
        props: true,
        component: () => import('@/views/InfluencerCampaigns'),
    },
    {
        name: 'influencer_campaign_posts',
        path: '/:brand_id/:campaign_id',
        props: true,
        component: () => import('@/views/InfluencerCampaignPosts'),
    },
    {
        name: '404',
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/NotFound'),
    }
]

export const history = createWebHistory();

const router = createRouter({history, routes});

router.beforeEach((to) => {
    const auth = store.state.auth.token;
    if (!auth && to.name !== 'home') {
        return {name: 'home'};
    }
    for (const match of to.matched) {
        if (match.meta && match.meta.tool && !store.state.auth.data.permissions.tools.includes(match.meta.tool)) {
            return {name: 'home'};
        }
    }
});

router.afterEach((to) => {
    // try {
    //     document.title = to.meta.title && to.meta.title(to);
    // } catch (e) {
    //     document.title = "Crispin Data+Analytics";
    // }
})

export default router;
