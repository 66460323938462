import {createLogger, createStore} from 'vuex'
import auth from '@/store/modules/auth'
import {blocking_auth_api} from "@/axios";

const debug = process.env.NODE_ENV !== 'production'

const state = () => ({
    processing: 0,
    brands: null,
})

const getters = {}

const actions = {
    load_brands({commit, state}) {
        if (state.brands === null)
            blocking_auth_api.get("/api/brand").then(resp => commit('set_brands', resp.data))
    },
}

const mutations = {
    get_processing_lock(state) {
        state.processing += 1
    },
    release_processing_lock(state) {
        state.processing -= 1
    },
    set_brands(state, brands) {
        state.brands = brands
    },
}

export default createStore({
    modules: {
        auth
    },
    state,
    actions,
    getters,
    mutations,
    strict: debug,
    plugins: debug ? [createLogger()] : []
})